<template>
  <div>
    <div
      class="flex flex-col px-6 bg-gray-50"
      style="height: auto; overflow: auto; max-height: 575px"
    >
      <div class="flex flex-col sm:flex-row items-center mb-1 sm:space-x-5">
        <div class="w-full sm:w-2/3 mt-4">
          <p class="mb-1 font-semibold text-gray-700">{{ $t("name") }}</p>
          <input
            type="text"
            name=""
            v-model.trim="name"
            placeholder="name"
            class="px-3 py-2 mb-4 bg-white border border-gray-200 rounded shadow-sm w-full"
          />
        </div>

        <div class="w-full sm:w-1/3 mt-4" style="height: auto">
          <p class="mb-1 font-semibold text-gray-700">
            {{ $t("# of slots") }}
          </p>
          <input
            type="text"
            name=""
            v-model.number="slots"
            placeholder="number of slots"
            class="px-3 py-2 mb-4 bg-white border border-gray-200 rounded shadow-sm w-full"
          />
        </div>
      </div>
      <div class="flex flex-col mb-1" style="height: 200px">
        <p class="mb-1 font-semibold text-gray-700">
          {{ $t("description") }}
        </p>
        <textarea
          type="text"
          name=""
          v-model="description"
          placeholder="Description..."
          class="px-3 py-2 mb-4 bg-white border border-gray-200 rounded shadow-sm w-full h-36"
        />
      </div>
      <div class="flex flex-col sm:flex-row items-center mb-1 sm:space-x-5">
        <div class="w-full sm:w-2/3 -mt-4" v-if="schedule || preselectDay">
          <p class="mb-1 font-semibold text-gray-700">
            {{ $t("day") }}
            <span class="font-bold text-xs text-green-800">
              ({{ dayName }})
            </span>
          </p>
          <el-date-picker
            class="rounded shadow-sm"
            style="width: 100%"
            v-model="workDay"
            type="date"
            placeholder="Pick a day"
          />
        </div>
        <div class="w-full sm:w-2/3" v-else>
          <p class="mb-1 font-semibold text-gray-700">
            {{ $t("period") }}
            <span class="font-bold text-xs text-green-800">
              {{ weekRange }}
            </span>
          </p>
          <el-date-picker
            style="width: 100%"
            class="w-full px-3 py-2 mb-4 bg-white border border-gray-200 rounded shadow-sm h-36"
            :picker-options="{
              firstDayOfWeek: 1
            }"
            format="dd.MM.yyyy"
            v-model="period"
            type="daterange"
            range-separator="-"
            start-placeholder="Start date"
            end-placeholder="End date"
          />
        </div>
        <div class="w-full sm:w-1/3 mt-2 sm:mt-0">
          <p class="mb-1 font-semibold text-gray-700">{{ $t("work hours") }}</p>
          <el-time-picker
            style="width: 100%"
            class="w-full px-3 py-2 mb-4 bg-white border border-gray-200 rounded shadow-sm h-36"
            is-range
            format="HH:mm"
            :picker-options="{
              format: 'HH:mm'
            }"
            v-model="hours"
            range-separator="-"
            start-placeholder="Start time"
            end-placeholder="End time"
          />
        </div>
      </div>
      <template v-if="!(schedule || preselectDay)">
        <p class="mb-1 font-semibold text-gray-700">{{ $t("work days") }}</p>
        <div class="flex">
          <label
            class="inline-flex items-center mr-4"
            v-for="{ label, value } in days"
            :key="value"
          >
            <input
              type="checkbox"
              class="form-checkbox h-5 w-5 text-gray-600"
              v-model="selectedDays[value]"
            />
            <span class="ml-2 text-gray-500 font-semibold">{{ label }}</span>
          </label>
        </div>
      </template>
      <hr class="my-7" />
      <!-- <div class="flex items-center mt-5 mb-1 space-x-4"></div> -->
      <template v-if="schedule">
        <p class="mb-1 font-semibold text-gray-700">
          {{ $t("agents") }}
        </p>
        <div class="grid grid-cols-2 gap-6 pb-6">
          <div
            class="flex flex-row items-center justify-between p-5 bg-white border border-gray-200 rounded shadow-sm w-full"
            v-for="slot in schedule.slots"
            :key="`slot-${slot.id}`"
          >
            <template v-if="slot.agent">
              <div class="flex flex-row items-center">
                <img
                  v-if="slot.agent.profile_image"
                  :src="slot.agent.profile_image"
                  class="rounded-full h-9 w-9 mr-3 object-cover"
                />
                <div
                  v-else
                  class="uppercase text-xs rounded-full h-9 w-9 mr-3 object-cover bg-blueGray-500 text-blueGray-50 font-bold flex items-center justify-center bg-gradient-to-r from-gray-500 to-blueGray-600"
                >
                  {{ getInitials(slot.agent.name) }}
                </div>
                <div class="flex flex-col">
                  <p class="font-semibold text-gray-800">
                    {{ slot.agent.name }}
                  </p>
                  <p class="text-gray-400 truncate w-32">
                    {{ slot.agent.email }}
                  </p>
                </div>
              </div>
              <h1
                @click="removeAgent(slot.id, slot.agent_id)"
                class="font-semibold text-red-400 cursor-pointer"
              >
                {{ $t("Remove") }}
              </h1>
            </template>
            <template v-else>
              <div class="flex flex-row items-center">
                <i class="far fa-user-circle mr-3 text-4xl" />
                <div class="font-semibold text-gray-800 cursor-pointer">
                  <!-- <SelectUserDropdown />  -->
                  <el-select
                    class="userSelect"
                    v-model="slot.agent"
                    filterable
                    :placeholder="$t(`select a user`)"
                    @change="addAgent(slot.agent)"
                  >
                    <el-option
                      v-for="user in $store.state.users.users"
                      :key="`${user.name}-${user.id}`"
                      :label="user.name"
                      :value="user.id"
                      class="flex items-center"
                    >
                      <div class="mr-2">
                        <img
                          v-if="user.profile_image"
                          :src="user.profile_image"
                          class="rounded-full h-6 w-6 object-cover"
                        />
                        <div
                          v-else
                          class="uppercase text-xs rounded-full h-6 w-6 object-cover bg-blueGray-500 text-blueGray-50 font-bold flex items-center justify-center bg-gradient-to-r from-gray-500 to-blueGray-600"
                        >
                          {{ getInitials(user.name) }}
                        </div>
                      </div>
                      <div class="mr-1">
                        {{ user.name }}
                      </div>
                      <!-- 
      <span style="float: left">{{ item.label }}</span>
      <span style="float: right; color: #8492a6; font-size: 13px">{{ item.value }}</span> -->
                    </el-option>
                  </el-select>
                </div>
              </div>
              <!-- <h1
                @click="addAgent()"
                class="font-semibold text-teal-700 cursor-pointer"
              >
                {{ $t("Add") }}
              </h1> -->
            </template>
          </div>
        </div>
      </template>
    </div>
    <div
      class="flex flex-row items-center justify-between p-5 bg-white border-t border-gray-200s rounded-bl-lg rounded-br-lg"
    >
      <p class="font-semibold text-gray-600 cursor-pointer" @click="closeMe">
        {{ $t("Cancel") }}
      </p>
      <button
        class="px-4 py-2 text-white font-semibold bg-blue-500 rounded"
        @click="handleCreateSchedule"
      >
        {{ $t("Save") }}
      </button>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import userMixins from "@/mixins/userMixins";
export default {
  name: "ScheduleForm",
  props: {
    schedule: {
      type: Object,
      require: false
    },
    preselectDay: {
      type: String,
      require: false
    }
  },
  mixins: [userMixins],
  data() {
    const startOfDay = new Date();
    startOfDay.setHours(0, 0, 0, 0);
    const endOfDay = new Date();
    endOfDay.setHours(23, 59, 0, 0);
    const hours = [startOfDay, endOfDay];

    return {
      days: [
        { label: "mo", value: 1 },
        { label: "tu", value: 2 },
        { label: "we", value: 3 },
        { label: "th", value: 4 },
        { label: "fr", value: 5 },
        { label: "sa", value: 6 },
        { label: "su", value: 7 }
      ],
      name: "",
      workDay: null,
      description: "",
      period: [],
      hours,
      slots: 1,
      selectedDays: {
        1: false,
        2: false,
        3: false,
        4: false,
        5: false,
        6: false,
        7: false
      }
    };
  },
  computed: {
    dayName() {
      return this.workDay ? moment(this.workDay).format("dddd") : "-";
    },
    weekRange() {
      if (this.period.length > 0) {
        const [start, end] = this.period;
        return `(${moment(start).isoWeek()} - ${moment(end).isoWeek()})`;
      }
      return "";
    }
  },
  methods: {
    removeAgent(slotId, agent_id) {
      this.$store.dispatch("schedule/removeAgent", {
        slotId,
        payload: {
          slot: {
            update_type: "remove_agent",
            agent_id
          }
        }
      });
    },
    addAgent(agent_id) {
      this.$store.dispatch("schedule/updateSchedule", {
        scheduleId: this.schedule.id,
        payload: {
          schedule: {
            update_type: "slot_update",
            agent_id
          }
        }
      });
    },
    closeMe() {
      this.$emit("closeModal");
    },
    getPayload() {
      const {
        name,
        description,
        period,
        hours,
        slots,
        selectedDays,
        workDay
      } = this;
      let payload = {
        name,
        description,
        location_id: 1,
        start_time: moment(hours[0]).format("YYYY-MM-DDTHH:mm:ss"),
        end_time: moment(hours[1]).format("YYYY-MM-DDTHH:mm:ss"),
        slots
      };
      if (this.preselectDay || this.schedule) {
        payload.start_period = moment(workDay).format("YYYY-MM-DDTHH:mm:ss");
        payload.end_period = moment(workDay).format("YYYY-MM-DDTHH:mm:ss");

        // just trying to convert the time to the correct timezone and day...
        let workDayDayFormat = moment(workDay).format("YYYY-MM-DD");
        let startTimeFormat = moment(hours[0]).format("HH:mm:ss");
        let endTimeFormat = moment(hours[1]).format("HH:mm:ss");
        payload.start_time = `${workDayDayFormat}T${startTimeFormat}`;
        payload.end_time = `${workDayDayFormat}T${endTimeFormat}`;
      } else {
        payload.start_period = moment(period[0]).format("YYYY-MM-DDT00:00:00");
        payload.end_period = moment(period[1]).format("YYYY-MM-DDT00:00:00");
        payload.days = Object.entries(selectedDays).reduce(
          (accu, [key, val]) => {
            if (val === true) {
              accu.push(parseInt(key));
            }
            return accu;
          },
          []
        );
      }
      return payload;
    },
    handleCreateSchedule() {
      const payload = {
        schedule: this.getPayload()
      };

      if (this.schedule) {
        //
      } else {
        this.$store.dispatch("schedule/createSchedules", payload);
        this.closeMe();
      }
    }
  },
  created() {
    if (this.schedule) {
      let { name, description, start_time, end_time, slots } = this.schedule;
      start_time = new Date(start_time);
      end_time = new Date(end_time);
      this.name = name;
      this.description = description;
      this.period = [start_time, end_time];
      this.workDay = new Date(start_time);
      this.hours = [start_time, end_time];
      this.slots = slots.length;
      const dayNumber = moment(start_time).weekday();
      this.selectedDays[dayNumber] = true;
    }
    if (this.preselectDay) {
      this.workDay = new Date(this.preselectDay);
    }
  }
};
</script>

<style>
.userSelect .el-input__inner {
  border: 0;
}
</style>
