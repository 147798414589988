<template>
  <div class="bg-gray-50 px-6 py-4 overflow-hidden">
    <el-date-picker
      class="mb-4"
      style="width:100%; !important"
      v-model="exportDateRange"
      type="daterange"
      range-separator="To"
      start-placeholder="Start date"
      end-placeholder="End date"
    >
    </el-date-picker>
    <div class="justify-end flex">
      <el-button @click="$emit('close')">
        {{ $t("Cancel") }}
      </el-button>
      <el-button type="primary" @click="handleExport">
        {{ $t("export") }}
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "ExportAttendance",
  data() {
    return {
      exportDateRange: []
    };
  },
  methods: {
    handleExport() {
      this.$store.dispatch("exportData/exportAttendance", {
        from: this.exportDateRange[0],
        to: this.exportDateRange[1]
      });
      this.$emit("close");
    }
  }
};
</script>
