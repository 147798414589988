<template>
  <div
    class="overflow-hidden flex justify-center h-screen items-center bg-gray-200 antialiased fixed top-0 left-0 w-screen z-50"
    style="background: rgba(0, 0, 0, 0.6)"
  >
    <div
      class="overflow-hidden flex flex-col w-11/12 sm:w-5/6 lg:w-1/2 max-w-2xl mx-auto rounded-lg border border-gray-300 shadow-xl"
    >
      <div
        class="flex flex-row justify-between p-6 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg"
      >
        <p class="font-semibold text-gray-800">{{ title }}</p>
        <i class="fas fa-times cursor-pointer" @click="closeMe" />
      </div>
      <slot class="overflow-hidden" />
      <!-- <div
        class="flex flex-row items-center justify-between p-5 bg-white border-t border-gray-200s rounded-bl-lg rounded-br-lg"
      >
        <p class="font-semibold text-gray-600 cursor-pointer" @click="closeMe">
          {{ $t("Cancel") }}
        </p>
        <button class="px-4 py-2 text-white font-semibold bg-blue-500 rounded">
          {{ $t("Save") }}
        </button>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  props: {
    title: {
      type: String,
      defualt: ""
    }
  },
  methods: {
    closeMe() {
      this.$emit("closeModal");
    }
  }
  // data() {
  //   return {
  //     showModal: false
  //   };
  // }
};
</script>
