<template>
  <div class="truncate">
    <i v-if="slotFilled" class="far fa-check-circle text-green-600" />
    <i v-else class="fas fa-check-circle text-green-600" />
    {{ name }}
  </div>
</template>

<script>
export default {
  name: "ScheduleCardStatus",
  props: {
    name: {
      type: String,
      required: true
    },
    slots: {
      type: Array,
      required: true
    }
  },
  computed: {
    slotFilled() {
      return this.slots.find(({ agent_id }) => agent_id == undefined);
    }
  }
};
</script>
