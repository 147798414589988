<template>
  <div class="flex flex-wrap text-xs items-center font-semibold">
    <div
      class="mr-1"
      :class="{
        'text-red-600': occupidSlots.length == 0,
        'text-green-800': occupidSlots.length == slots.length
      }"
    >
      [{{ occupidStatus }}]
    </div>
    <div class="mr-1" v-for="slot in occupidSlots" :key="slot.id">
      <img
        v-if="slot.agent.profile_image"
        :src="slot.agent.profile_image"
        class="rounded-full h-6 w-6 object-cover"
      />
      <div
        v-else
        class="uppercase text-xs rounded-full h-6 w-6 object-cover bg-blueGray-500 text-blueGray-50 font-bold flex items-center justify-center bg-gradient-to-r from-gray-500 to-blueGray-600"
      >
        {{ getInitials(slot.agent.name) }}
      </div>
    </div>
  </div>
</template>

<script>
import formatting from "../mixins/formatting";
import userMixins from "@/mixins/userMixins";
export default {
  name: "ScheduleCardAgents",
  props: {
    slots: {
      type: Array,
      required: true
    }
  },
  mixins: [formatting, userMixins],
  computed: {
    occupidSlots() {
      return this.slots.filter(({ agent_id }) => agent_id != undefined);
    },
    occupidStatus() {
      return `${this.occupidSlots.length}/${this.slots.length}`;
    }
  }
};
</script>
