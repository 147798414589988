<template>
  <div
    class="rounded-xl shadow-sm px-4 py-3 mb-6 cursor-pointer dropzone"
    :style="`width:${cardWidth}px`"
    :data-schedule-id="schedule.id"
    :data-schedule-start-time="schedule.start_time"
    :data-schedule-end-time="schedule.end_time"
    :class="{
      'border-rose-700 border-l-8 bg-rose-50': hasDraggedUser,
      'bg-gray-50': !hasDraggedUser
    }"
  >
    <div class="font-bold text-gray-600 text-sm uppercase flex justify-between">
      <ScheduleCardStatus :name="schedule.name" :slots="schedule.slots" />
      <i class="fas fa-pen-square cursor-pointer" @click="handleEditClick" />
    </div>
    <div class="text-blue-700 text-xs font-bold">
      {{ timeOnly(schedule.start_time) }}
      <i class="fas fa-minus text-xs mx-1" />
      {{ timeOnly(schedule.end_time) }}
    </div>
    <div class="text-gray-700 text-xs py-1 pb-2">
      {{ schedule.description }}
    </div>
    <ScheduleCardAgents :slots="schedule.slots" />
  </div>
</template>

<script>
import formatting from "../mixins/formatting";
import userMixins from "@/mixins/userMixins";
import ScheduleCardStatus from "./ScheduleCardStatus.vue";
import ScheduleCardAgents from "./ScheduleCardAgents.vue";
export default {
  name: "ScheduleCard",
  props: {
    schedule: {
      type: Object,
      required: true
    },
    draggedUser: {
      type: Number,
      required: false
    },
    cardWidth: {
      type: Number,
      default: 200
    }
  },
  components: { ScheduleCardStatus, ScheduleCardAgents },
  mixins: [formatting, userMixins],
  computed: {
    hasDraggedUser() {
      return this.schedule.slots.find(
        ({ agent_id }) => agent_id && agent_id == this.draggedUser
      );
    }
  },
  methods: {
    handleEditClick() {
      this.$emit("editSchedule", this.schedule);
    }
  }
};
</script>
