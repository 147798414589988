<template>
  <div class="md:-mt-12">
    <div class="fixed top-0 min-w-full bg-gray-600 md:pt-6 pb-48 pt-12 z-0" />
    <div class="pt-10 px-8 md:px-14">
      <div class="rounded-t bg-white mb-0 px-6 py-6 relative z-10">
        <div class="text-center flex justify-between">
          <div class="flex">
            <h6 class="text-blueGray-700 text-xl font-bold">
              {{ $t("Schedule") }}
            </h6>
            <button
              @click="addBatch"
              class="ml-2 bg-green-500 text-white active:bg-emerald-640 font-bold uppercase text-xs px-2 py-1 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
            >
              {{ $t("add batch") }}
            </button>
            <button
              @click="exportModal = true"
              class="ml-2 bg-gray-500 text-white active:bg-emerald-640 font-bold uppercase text-xs px-2 py-1 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
            >
              {{ $t("export data") }}
            </button>
          </div>

          <div class="flex items-center">
            <i
              class="fas fa-chevron-left mx-3 cursor-pointer"
              @click="prevWeek"
            />
            <div class="font-semibold">
              <div class="font-bold px-4 text-gray-500 text-sm">
                <span>{{ getDateOnly(fromDate) }}</span>
                <i class="fas fa-minus text-xs mx-2" />
                <span>{{ getDateOnly(toDate) }}</span>
                <span class="ml-2 text-blue-640"> (#{{ currentWeek }}) </span>
              </div>
            </div>
            <i
              class="fas fa-chevron-right mx-3 cursor-pointer"
              @click="nextWeek"
            />
          </div>
        </div>
      </div>
      <div
        style="height: calc(100vh - 255px)"
        class="relative flex flex-col min-w-0 break-words w-full mb-6 mx-auto shadow-lg rounded-lg rounded-t-none bg-blueGray-100 border-0"
      >
        <div class="flex">
          <div class="w-64">
            <h3 class="px-6 font-semibold mt-3">
              {{ $t("agents") }}
            </h3>
            <div
              class="overflow-scroll text-center"
              style="height: calc(100vh - 293px)"
            >
              <i
                v-if="$store.state.users.loading"
                class="fas fa-circle-notch fa-spin mt-4"
              />
              <template v-else>
                <div
                  draggable="true"
                  class="py-2 px-6 bg-white m-3 rounded-sm text-left draggable"
                  v-for="user in $store.state.users.users"
                  :key="`user-id-${user.id}`"
                  :data-user-id="user.id"
                >
                  <div class="font-semibold">
                    {{ user.name }}
                  </div>
                  <div class="text-xs -mt-1">({{ user.email }})</div>
                </div>
              </template>
            </div>
          </div>
          <div
            class="flex h-full absolute ml-64 pr-4 overflow-auto top-0"
            style="width: calc(100% - 16rem)"
          >
            <transition-group
              v-for="(day, i) in weekArray"
              :key="day.format()"
              tag="div"
              style="width: calc(100% / 7); min-width: 200px"
              name="sortableCards"
              class="disable-marker p-3 pt-0 clickable sortable-animation bg-blueGray-100"
              :data-date="day"
              sortable
            >
              <div
                ref="dayCols"
                :key="`${i}-header`"
                class="head flex justify-between py-3 px-2 items-center text-sm sticky top-0 bg-blueGray-100 z-50"
              >
                <div class="font-semibold text-gray-640">
                  {{ getDateNoYearOnly(day) }}
                  <span class="font-bold text-blue-500">
                    [{{ getWeekDayAbbrev(day) }}]
                  </span>
                  <span
                    class="bg-green-500 rounded px-1 text-green-50 text-xs"
                    v-if="today.isSame(day)"
                  >
                    {{ $t("today") }}
                  </span>
                </div>

                <i
                  class="fas fa-plus-square text-gray-500 cursor-pointer hover:text-gray-700"
                  @click="newSchedule(day)"
                />
              </div>
              <ScheduleCard
                sortItem
                v-for="(schedule, index) in schedules[getDateOnly(day)]"
                :schedule="schedule"
                :key="`${schedule.name}-${index}-${getDateOnly(day)}`"
                :draggedUser="parseInt(draggedUser)"
                @editSchedule="editSchedule"
                :cardWidth="cardWidth"
              />
            </transition-group>
          </div>
        </div>
      </div>
    </div>
    <Modal
      v-if="showModal"
      @closeModal="showModal = false"
      :title="$t(modalText)"
    >
      <ScheduleForm
        @closeModal="showModal = false"
        :schedule="schedule"
        :preselectDay="preselectDay"
      />
    </Modal>

    <Modal
      v-if="exportModal"
      @closeModal="exportModal = false"
      :title="$t('export data')"
    >
      <ExportAttendance v-if="exportModal" @close="exportModal = false" />
    </Modal>
  </div>
</template>

<script>
import moment from "moment";
import {
  Sortable
  // Plugins
} from "@shopify/draggable";
import formatting from "../mixins/formatting";
import Modal from "../components/Modal.vue";
import ScheduleCard from "../components/ScheduleCard.vue";
import ScheduleForm from "../components/ScheduleForm.vue";
import ExportAttendance from "../components/ExportAttendance.vue";
export default {
  name: "Schedule",
  components: { ScheduleCard, Modal, ScheduleForm, ExportAttendance },
  mixins: [formatting],
  data() {
    const today = moment().startOf("day");
    const { fromDate, toDate, currentWeek } = this.getWeekStartEnd(today);

    return {
      showModal: false,
      today,
      currentWeek,
      fromDate,
      toDate,
      sortable: null,
      draggableEvts: [],
      draggedUser: null,
      schedule: null,
      scheduleId: null,
      preselectDay: null,
      cardWidth: null,
      exportModal: false
    };
  },
  computed: {
    modalText() {
      if (this.preselectDay) return "create schedule";
      if (this.schedule) return "edit schedule";
      return "batch create schedules";
    },
    schedules() {
      return this.$store.state.schedule.schedules.length > 0
        ? this.$store.state.schedule.schedules.reduce((accu, item) => {
            const key = this.getDateOnly(item.start_time);
            if (key in accu) {
              accu[key].push(item);
            } else {
              accu[key] = [item];
            }
            return accu;
          }, {})
        : {};
    },
    weekArray() {
      return [...Array(7).keys()].map(i =>
        moment(this.fromDate).add(i, "days")
      );
    }
  },
  watch: {
    schedules() {
      setTimeout(() => {
        this.bindDraggableUsers();
      }, 500);

      this.schedule = this.$store.state.schedule.schedules.find(
        schedule => schedule.id == this.scheduleId
      );
    }
  },
  methods: {
    newSchedule(day) {
      this.schedule = null;
      this.scheduleId = null;
      this.preselectDay = moment(day).toString();
      this.showModal = true;
    },
    editSchedule(schedule) {
      this.schedule = schedule;
      this.scheduleId = schedule.id;
      this.preselectDay = null;
      this.showModal = true;
    },
    addBatch() {
      this.schedule = null;
      this.scheduleId = null;
      this.preselectDay = null;
      this.showModal = true;
    },
    setWeek(d) {
      const { fromDate, toDate, currentWeek } = this.getWeekStartEnd(d);
      this.fromDate = fromDate;
      this.toDate = toDate;
      this.currentWeek = currentWeek;
      // settime out to make sure the draggble items are loaded
      setTimeout(() => {
        this.setupSortable();
        this.bindDraggableUsers();
      }, 500);
    },
    nextWeek() {
      this.setWeek(moment(this.toDate).add(1, "days"));
    },
    prevWeek() {
      this.setWeek(moment(this.fromDate).subtract(1, "days"));
    },
    getWeekStartEnd(d) {
      return {
        fromDate: moment(d).startOf("isoWeek"),
        toDate: moment(d).endOf("isoWeek").startOf("day"),
        currentWeek: moment(d).isoWeek()
      };
    },
    setupSortable() {
      const containers = document.querySelectorAll("[sortable]");

      if (containers.length === 0) return false;

      this.sortable = new Sortable(containers, {
        draggable: "[sortItem]",
        // so items are clickable on the card before sortable takes over
        delay: 100
        // plugins: [Plugins.ResizeMirror]
      });

      let scheduleId;
      let originalContainer;
      let start_time;
      let end_time;

      this.sortable.on("drag:start", evt => {
        originalContainer = evt.sourceContainer;
        scheduleId = null;
        start_time = null;
        end_time = null;
      });

      this.sortable.on("drag:stop", () => {
        if (scheduleId != null && start_time != null && end_time != null) {
          this.$store.dispatch("schedule/updateSchedule", {
            scheduleId,
            payload: {
              schedule: { start_time, end_time }
            }
          });
        }
      });

      this.sortable.on("sortable:sorted", evt => {
        if (
          evt.newContainer?.dataset?.date == originalContainer?.dataset?.date
        ) {
          scheduleId = null;
          start_time = null;
          end_time = null;
          return;
        }

        const scheduleStartTime =
          evt.dragEvent?.data?.source?.dataset?.scheduleStartTime;
        const scheduleEndTime =
          evt.dragEvent?.data?.source?.dataset?.scheduleEndTime;

        scheduleId = evt.dragEvent?.data?.source?.dataset?.scheduleId;
        const newDate = moment(evt.newContainer?.dataset?.date).format(
          "YYYY-MM-DD"
        );
        let startTime = moment(scheduleStartTime).format("HH:mm:ss.sssZ");
        let endTime = moment(scheduleEndTime).format("HH:mm:ss.sssZ");

        start_time = [newDate, startTime].join("T");
        end_time = [newDate, endTime].join("T");
      });
    },
    resetDraggableUsers() {
      this.draggableEvts.forEach(({ evt, el, handler }) => {
        el.removeEventListener(evt, handler);
      });
      this.draggableEvts = [];
    },
    setDraggableUsers() {
      this.draggableEvts.forEach(({ evt, el, handler }) => {
        el.addEventListener(evt, handler);
      });
    },
    setupDraggableUsers(draggables, dropzones) {
      draggables.forEach(el => {
        this.draggableEvts.push({
          el,
          evt: "dragstart",
          handler: () => {
            this.draggedUser = el.dataset.userId;
            el.classList.add("bg-blueGray-300");
          }
        });
        this.draggableEvts.push({
          el,
          evt: "dragend",
          handler: () => {
            this.draggedUser = null;
            el.classList.remove("bg-blueGray-300");
          }
        });
      });

      const dragoverClasses = [
        "dragover",
        "border-1",
        "border-dashed",
        "border-blueGray-800"
      ];

      const { dispatch } = this.$store;

      dropzones.forEach(el => {
        this.draggableEvts.push({
          el,
          evt: "dragover",
          handler: e => {
            e.preventDefault();
            el.classList.add(...dragoverClasses);
          }
        });

        this.draggableEvts.push({
          el,
          evt: "dragleave",
          handler: () => el.classList.remove(...dragoverClasses)
        });

        this.draggableEvts.push({
          el,
          evt: "drop",
          handler: e => {
            e.preventDefault();
            el.classList.remove(...dragoverClasses);
            dispatch("schedule/updateSchedule", {
              scheduleId: el.dataset.scheduleId,
              payload: {
                schedule: {
                  update_type: "slot_update",
                  agent_id: this.draggedUser
                }
              }
            });
          }
        });
      });
    },
    bindDraggableUsers() {
      const draggables = document.querySelectorAll(".draggable");
      const dropzones = document.querySelectorAll(".dropzone");

      if (dropzones.length === 0 || draggables.length === 0) return false;

      // note: crazy event listener binding and removing so wont have event listeners attached multiple times to the same element
      // that will cause multiple patch or posts
      // https://dev.to/smotchkkiss/function-identity-in-javascript-or-how-to-remove-event-listeners-properly-1ll3

      // 1. reset and remove all event this.$listeners
      this.resetDraggableUsers();
      // 2. find all dynamic elements that needs to be binded
      this.setupDraggableUsers(draggables, dropzones);
      // 3. attach new listeners
      this.setDraggableUsers();
    },
    getColWidth() {
      this.cardWidth = this.$refs.dayCols[0].offsetWidth;
    }
  },
  mounted() {
    this.setupSortable();
    setTimeout(() => this.bindDraggableUsers(), 2000);
    this.getColWidth();
  },
  created() {
    this.$store.dispatch("users/getAllUsers");
    this.$store.dispatch("schedule/getAllSchedules");
    window.addEventListener("resize", this.getColWidth);
  },
  destroyed() {
    window.removeEventListener("resize", this.getColWidth);
  }
};
</script>

<style>
.sortable-animation > * {
  transform: translate3d(0, 0, 0);
}
.sortableCards-move {
  transition: transform cubic-bezier(0.22, 0.61, 0.36, 1) 500ms;
  background-color: rgba(189, 177, 167, 0.187);
}
/* .sortableCards-enter,
.sortableCards-leave-to {
  transition: 5s;
  opacity: 0;
} */
.draggable-source--is-dragging {
  opacity: 0.3;
}
.draggable-container--over {
  animation: draggablePulseBg cubic-bezier(0.22, 0.61, 0.36, 1) 1500ms infinite;
}
.draggable-container--over > div.head {
  background: transparent;
}
.draggable-mirror {
  position: absolute;
  padding: 1rem;
  background-color: #fff;
  transition: all 0ms;
  overflow: auto;
  box-shadow: 0 0.4rem 3rem rgba(0, 0, 0, 0.35),
    0 0.1rem 0.5rem rgba(0, 0, 0, 0.2);
  z-index: 10;
  cursor: move;
  transition: top 0ms left 0ms;
  min-width: 200px;
}
.draggable-source--placed {
  animation: placedItem cubic-bezier(0.22, 0.61, 0.36, 1) 0.5s;
}
.sortableCards-enter-active,
.sortableCards-leave-to {
  opacity: 0;
}
.sortableCards-leave-active {
  position: absolute;
}

.dragover,
.dragging {
  animation: draggablePulseBg2 cubic-bezier(0.22, 0.61, 0.36, 1) 1500ms infinite;
  border: 1px dashed !important;
}

@keyframes draggablePulseBg2 {
  0% {
    opacity: 0.7;
  }
  35% {
    background-color: rgba(103, 93, 125, 0.132);
    opacity: 1;
  }
  100% {
    opacity: 0.7;
  }
}

@keyframes draggablePulseBg {
  0% {
    background-color: transparent;
    outline: solid 0.1rem transparent;
  }
  35% {
    background-color: rgba(0, 0, 0, 0.05);
    outline: solid 0.1rem rgba(0, 0, 0, 0.1);
  }
  100% {
    background-color: transparent;
    outline: solid 0.1rem transparent;
  }
}

@keyframes placedItem {
  from {
    transform: scale(1.065);
    box-shadow: 0 0.25rem 2rem rgba(0, 0, 0, 0.25),
      0 0.1rem 0.5rem rgba(0, 0, 0, 0.15);
  }
  to {
    transform: scale(1);
    box-shadow: 0 0.4rem 3rem rgba(0, 0, 0, 0.35),
      0 0.1rem 0.5rem rgba(0, 0, 0, 0.2);
  }
}
</style>
